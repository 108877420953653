import { useQuery } from "@tanstack/react-query";
import { QueryFunction, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { getSession } from "next-auth/react";

import { GetSubscriptionPayload } from "../../../../cross-platform-resources/back-end/Pricing";
import {
  FormattedError,
  forceSignOutIfInvalidAccessToken,
  sentryReport,
} from "../../../Error";

type QueryData = GetSubscriptionPayload;

const queryKey = ["pricing/get-subscription"];

const queryFn: QueryFunction<QueryData, typeof queryKey> = async (_context) => {
  const session = await getSession();

  // No auth
  if (!session) {
    return Promise.reject({
      response: { data: { message: "No session found." } },
    } as FormattedError);
  }

  try {
    const apiEndpoint = queryKey[0];

    const res = await axios({
      method: "get",
      url: `${process.env.NEXT_PUBLIC_BACKEND_HOST}/${apiEndpoint}`,
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });

    return res.data;
  } catch (err) {
    sentryReport(err);
    forceSignOutIfInvalidAccessToken(err);
    return Promise.reject(err);
  }
};

const useQueryWrapper = (options?: Omit<UseQueryOptions, "queryKey">) => {
  return useQuery<QueryData>({
    queryKey,
    queryFn,

    retry: false,

    // https://www.codemzy.com/blog/react-query-cachetime-staletime
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins

    ...(options as any),
  });
};

const GetSubscription = {
  queryKey,
  queryFn,
  useQueryWrapper,
};

export default GetSubscription;
