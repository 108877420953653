// Format: MM/DD/YYYY
export const simpleDateString = (dateObj: Date): string => {
  // getMonth is zero-based
  let month = (dateObj.getMonth() + 1).toString();
  month = month.length === 1 ? `0${month}` : month;

  let date = dateObj.getDate().toString();
  date = date.length === 1 ? `0${date}` : date;

  const year = dateObj.getFullYear();

  return `${month}/${date}/${year}`;
};
