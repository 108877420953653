const NODE_ENV: string | undefined =
  process.env.NODE_ENV ?? process.env.NEXT_PUBLIC_NODE_ENV;

export const TRIAL_DURATION_IN_SECONDS =
  NODE_ENV === "development" ? 30 : 60 * 24 * 60 * 60; // Trial lasts 60 days

export const SCHEDULE_FORCE_CANCEL_MARGIN_IN_SECONDS = 30; // Allow 30 seconds margin for schedule to force cancel

export const SEND_REMINDER_EMAIL_MARGIN_IN_SECONDS = 60; // Allow 60 seconds margin then send reminder email

export const TRIAL_END_MARGIN_IN_SECONDS =
  NODE_ENV === "development" ? 60 : 5 * 60; // Allow 5 minutes margin for trial actually ends

export const EARLY_ACCESS_MAX_COUNT = NODE_ENV === "development" ? 1 : 200;

export enum EarlyAccessStatus {
  ACTIVE = "active",
  WAITLIST = "waitlist",
  WAITLIST_SENT = "waitlist-sent",
  OUTDATED = "outdated",
}
