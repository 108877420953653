import React from "react";

import useAuth from "@/utils/Hooks/useAuth";

import InstallExtensionBanner, {
  useCheckExtensionInstalled,
} from "./InstallExtensionBanner";
import LimitedOfferBanner, {
  useShowLimitedOfferBanner,
} from "./LimitedOfferBanner";

export function useCheckBanners() {
  const { isAuthenticated } = useAuth({});

  const showLimitedOfferBanner = useShowLimitedOfferBanner();
  const didInstallExtension = useCheckExtensionInstalled();

  const isShowLimitedOfferBanner = showLimitedOfferBanner.show;
  const isShowInstallExtensionBanner = isAuthenticated && !didInstallExtension;

  return {
    showBanner: isShowLimitedOfferBanner || isShowInstallExtensionBanner,
    showLimitedOfferBanner: isShowLimitedOfferBanner,
    showInstallExtensionBanner: isShowInstallExtensionBanner,
  };
}

export default function HeadBanners() {
  const checkBanners = useCheckBanners();

  if (checkBanners.showInstallExtensionBanner) {
    return <InstallExtensionBanner />;
  }

  if (checkBanners.showLimitedOfferBanner) {
    return <LimitedOfferBanner />;
  }

  return null;
}
