import { JSXElementConstructor, PropsWithChildren, ReactElement } from "react";

import { Fade, Modal } from "@mui/material";

import styles from "./CustomModal.module.scss";

type Props = PropsWithChildren & {
  isOpen: boolean;
  onClose: (event: object, reason: "backdropClick" | "escapeKeyDown") => void;
};

const CustomModal = (props: Props) => {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      classes={{
        root: styles.root,
        backdrop: styles.backdrop,
      }}
    >
      <Fade in={props.isOpen}>
        {
          props.children as ReactElement<
            any,
            string | JSXElementConstructor<any>
          >
        }
      </Fade>
    </Modal>
  );
};

export default CustomModal;
