import { useEffect, useState } from "react";

import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";

import { WindowMessagePurpose } from "@/cross-platform-resources/chrome-extension/WindowMessage";

import { queryClient } from "../../pages/_app";

export const signOutWrapper = () => {
  // Post message to Chrome extension
  // https://github.com/nextauthjs/next-auth/discussions/6021
  window.postMessage(
    { purpose: WindowMessagePurpose.USER_SIGN_OUT },
    process.env.NEXT_PUBLIC_FRONTEND_HOST as string
  );

  return signOut({
    redirect: false,
  });
};

export default function useAuth(props: { blockAutoRedirect?: boolean }) {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const needAuthRoutes = [
    "/account",
    "/dashboard",
    "/board",
    "/internal",
    "/route",
    "/pricing/premium/confirm",
  ]; // Must logged in

  const noAuthRoutes = [
    "/signin",
    "/signup",
    "/waitlist",
    "/signup-waitlist",
    "/waitlist-success",
  ]; // Must not be logged in

  // Effect: Clear cache
  useEffect(() => {
    if (status === "loading") {
      return;
    }

    if (status === "unauthenticated") {
      queryClient.clear();
    }
  }, [JSON.stringify(session), status]);

  // Effect: Redirect
  useEffect(() => {
    // Note:
    // - router.asPath contains query "?params=..." too.
    // - router.route only contains the path, excluding the query part

    const signinUrl = `/signin?reroute=${encodeURIComponent(
      // remove first slash since signin reroute param doesn't need it
      router.asPath.substring(1)
    )}`;

    if (status === "loading") {
      return;
    }

    if (session?.error === "RefreshAccessTokenError") {
      signOut({
        redirect: false,
      });
      return;
    }

    const rootRoute = "/" + router.route.substring(1);

    // Unauthenticated
    if (status === "unauthenticated") {
      let allow = true;

      needAuthRoutes.forEach((route) => {
        if (rootRoute.indexOf(route) === 0) {
          allow = false;
        }
      });

      if (!props.blockAutoRedirect && !allow) {
        router.replace(signinUrl);
      }

      setIsAuthenticated(false);
    }

    // Authenticated
    else {
      let allow = true;

      noAuthRoutes.forEach((route) => {
        if (rootRoute.indexOf(route) === 0) {
          allow = false;
        }
      });

      if (!props.blockAutoRedirect && !allow) {
        router.replace("/board");
      }

      setIsAuthenticated(true);
    }
  }, [JSON.stringify(session), status, props.blockAutoRedirect, router]);

  return {
    session,
    authStatus: status,
    isAuthenticated, // False by default + No need 'loading' state like status
  };
}
