import * as Sentry from "@sentry/nextjs";
import * as util from "util"; // has no default export
import { signOutWrapper } from "./Hooks/useAuth";

/**
 * This function should only be used to report "Internal error." and unexpected errors.
 *
 * For normal user errors, simply handle or print error message.
 */
export const sentryReport = (err: any, isInternalTool = false): void => {
  if (process.env.NODE_ENV !== "production" || isInternalTool) {
    if (isFormattedError(err)) {
      console.error(ErrorWrap(err.response.data.message));
    } else {
      console.error(ErrorWrap(err));
    }
  }

  if (process.env.NODE_ENV === "production") {
    if (
      isFormattedError(err) &&
      err.response.data.message !== "Internal error."
    ) {
      console.error(err.response.data.message);
    }
  }

  // Log internal errors or unformatted error
  if (
    !isFormattedError(err) ||
    (isFormattedError(err) && err.response.data.message === "Internal error.")
  ) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(ErrorWrap(err));
    }
  }
};

export const ErrorWrap = (err: any): Error => {
  let formatError: Error = err;

  if (formatError instanceof Error === false) {
    if (typeof err === "object") {
      // https://stackoverflow.com/questions/11616630/how-can-i-print-a-circular-structure-in-a-json-like-format
      // I avoid JSON.stringify because err can be cyclic JSON.
      formatError = new Error(util.inspect(err, { compact: false, depth: 5 }));
    } else {
      formatError = new Error(String(err));
    }
  }

  return formatError;
};

/**
 * Check if error is formatted as AxiosError and has data in it or not.
 *
 * If passed from our server, all errors should be objects that have either "message" or "recaptchaError".
 *
 * @param err
 * @returns true or false
 */
export const isFormattedError = (err: any): err is FormattedError => {
  return (
    err &&
    typeof err === "object" &&
    err.response &&
    err.response.data &&
    err.response.data.message
  );
};

export type FormattedError = {
  response: {
    data: {
      message: string;
      recaptchaError?: string; // ReCaptcha Error
      origin?: string; // CORS error
    };
  };
};

/**
 * If access token is somehow invalid due to various reasons (e.g change password):
 *
 * Then force signing out so that user needs to sign in again.
 *
 * USAGE: Use in .catch(err) whenever calling axios with accessToken
 *
 * NOTE: signOut currently only works on client-side.
 * Thus, must use "signal only" for server-side.
 * Check pages/dashboard for an example.
 *
 * @param err
 * @returns signal to force sign out
 */
export const forceSignOutIfInvalidAccessToken = (
  err: any,
  action: "sign out" | "signal only" = "sign out"
): boolean => {
  if (
    isFormattedError(err) &&
    err.response.data.message.includes("Try signing in again.")
  ) {
    if (action === "sign out") {
      signOutWrapper();
    }

    return true;
  }

  return false;
};
