import { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useRouter } from "next/router";

import CloseIcon from "@/public/close.svg";
import CoverBackgroundSvg from "@/public/cover_background.svg";
import LimitedOfferSvg from "@/public/limited-offer.svg";
import RiskFreeSvg from "@/public/risk-free.svg";

import { TRIAL_DURATION_IN_SECONDS } from "../../../cross-platform-resources/back-end/EarlyAccess";
import QueryStringCenter from "../../../cross-platform-resources/front-end/QueryStringCenter";
import { simpleDateString } from "../../../utils/DateTime";
import {
  forceSignOutIfInvalidAccessToken,
  sentryReport,
} from "../../../utils/Error";
import useAuth from "../../../utils/Hooks/useAuth";
import GetSubscription from "../../../utils/Queries/pricing/get-subscription";
import CustomModal from "../../CustomModal";

import styles from "./RedeemOfferModal.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

const RedeemOfferModal = (props: Props) => {
  const router = useRouter();
  const { session, authStatus } = useAuth({});

  const [loadingModal, setLoadingModal] = useState({ open: false });

  const {
    isLoading,
    isRefetching,
    data: subscription_data,
    error: subscription_error,
    refetch: subscription_refetch,
  } = GetSubscription.useQueryWrapper({
    enabled: authStatus === "authenticated",
  });

  // Check error of query
  useEffect(() => {
    if (isLoading === false && isRefetching === false && subscription_error) {
      sentryReport(subscription_error);
    }
  }, [isLoading, isRefetching, subscription_error]);

  const firstDatePayment = () => {
    if (!subscription_data?.earlyAccessInfo?.activeAt) {
      return simpleDateString(new Date());
    }

    const earlyAccessStart = new Date(
      subscription_data.earlyAccessInfo.activeAt
    );

    const earlyAccessEnd = new Date(
      (earlyAccessStart.getTime() / 1000 + TRIAL_DURATION_IN_SECONDS) * 1000
    );

    return simpleDateString(earlyAccessEnd);
  };

  const onRedeemOffer = () => {
    if (!session) {
      return;
    }

    setLoadingModal({ open: true });

    axios({
      method: "post",
      url: `${process.env.NEXT_PUBLIC_BACKEND_HOST}/early-access/redeem-offer-session`,
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
      data: {
        currentRoute: router.route,
        currentAsPath: router.asPath,
      },
    })
      .then((res) => {
        const checkoutSessionUrl: string = res.data;

        // Case 1: If user does not setup a payment method yet, move user to checkout session
        if (checkoutSessionUrl) {
          return router.push(checkoutSessionUrl);
        }

        // Case 2: If user already has a payment method, refetch data and show AfterAddPaymentModal
        subscription_refetch().then(() => {
          setLoadingModal({ open: false });

          const url = new URL(
            process.env.NEXT_PUBLIC_FRONTEND_HOST + router.asPath
          );

          const newSearch =
            url.search.length === 0
              ? `?${QueryStringCenter.GLOBAL.EARLY_ACCESS_PROMOTION_USER_ALREADY_HAS_PAYMENT_METHOD}`
              : url.search +
                `&${QueryStringCenter.GLOBAL.EARLY_ACCESS_PROMOTION_USER_ALREADY_HAS_PAYMENT_METHOD}`;

          router.replace(router.route + newSearch);
        });
      })
      .catch((err) => {
        sentryReport(err);
        forceSignOutIfInvalidAccessToken(err);

        setLoadingModal({ open: false });
      });
  };

  const paymentAmount = () => {
    const payment = 1.99 * 6 * (75 / 100);
    return `$${payment.toFixed(2)} (before tax)`;
  };

  return (
    <CustomModal isOpen={props.open} onClose={props.onClose}>
      <div className={styles.root}>
        <CoverBackgroundSvg
          className={styles.cover}
          preserveAspectRatio="none"
        />

        <div className={styles.header}>
          <p>
            <span>🎉</span> Redeem 25% off for 6 months
          </p>
          <div className={styles.iconContainer}>
            <CloseIcon
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>

        <div className={styles.images}>
          <LimitedOfferSvg />
          <RiskFreeSvg />
        </div>

        <div className={styles.text}>
          <p>
            In the following screen, you will be asked to provide a payment
            method that can be used to schedule payment for this offer when
            trial ends.
          </p>

          <p>
            Our Premium plan’s pricing for 6 months is ${1.99 * 6}. With 25%
            off, you only have to pay ${(1.99 * 6 * (75 / 100)).toFixed(2)},
            which is less than a pizza.
          </p>

          <p className={styles.bold}>
            First payment of {paymentAmount()} will be made on{" "}
            {firstDatePayment()} on your behalf.
          </p>

          <p>You can always make changes to this schedule anytime.</p>
        </div>

        <div className={styles.action}>
          <button
            onClick={() => {
              onRedeemOffer();
            }}
          >
            Redeem Offer
          </button>

          <CustomModal
            isOpen={loadingModal.open}
            onClose={() => {
              return;
            }}
          >
            <div className={styles["loading-container"]}>
              <p>Loading...</p>
              <CircularProgress />
            </div>
          </CustomModal>
        </div>
      </div>
    </CustomModal>
  );
};

export default RedeemOfferModal;
