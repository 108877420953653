const QueryStringCenter = {
  dashboard: {
    DASHBOARD_ID: "dashboard_id",

    TOUR_GUIDE: "tour_guide",

    CLAIM_EARLY_ACCESS_PROMOTION: "claim_early_access_promotion",
    CLAIM_FIRST_FREE_WEEK_PROMOTION: "claim_first_free_week_promotion",
  },

  route: {
    Q: "q", // Query shortcut string
    NOTLOGIN: "notlogin", // Search engine to use when not login
  },

  signin: {
    Q: "q", // Query shortcut string
  },

  signup_waitlist: {
    EMAIL: "email",
    SIGN_UP_UNIQUE_CODE: "sign_up_unique_code",
  },

  pricing: {
    premium: {
      confirm: {
        CYCLE: "cycle",
      },
    },
  },

  board: {
    BOARD_ID: "board_id",

    TOUR_GUIDE: "tour_guide",

    CLAIM_EARLY_ACCESS_PROMOTION: "claim_early_access_promotion",
    CLAIM_FIRST_FREE_WEEK_PROMOTION: "claim_first_free_week_promotion",
  },

  about: {
    REFERRER: "referrer",
  },

  GLOBAL: {
    REROUTE: "reroute",

    EARLY_ACCESS_PROMOTION_SUCCESS_ADD_PAYMENT_METHOD:
      "early_access_promotion_success_add_payment_method",
    EARLY_ACCESS_PROMOTION_USER_ALREADY_HAS_PAYMENT_METHOD:
      "early_access_promotion_user_already_has_payment_method",
  },
};

export default QueryStringCenter;
