import { EarlyAccessStatus } from "./EarlyAccess";

const NODE_ENV: string | undefined =
  process.env.NODE_ENV ?? process.env.NEXT_PUBLIC_NODE_ENV;

export const FIRST_FREE_WEEK_PROMOTION_DURATION_IN_SECONDS =
  NODE_ENV === "development" ? 2 * 60 : 7 * 24 * 60 * 60; // First free week promotion only available in 7 days

export const FREE_PLAN_ACTIVE_LINKS_LIMIT = 15;
export const FREE_PLAN_BOARDS_LIMIT = 10;

export enum PricingPlan {
  FREE = "free",

  PREMIUM_1MO = "premium-1mo",
  PREMIUM_6MO = "premium-6mo",

  SUPER_USER = "super-user",
}

export enum CheckoutSessionPurpose {
  OPT_IN_PREMIUM_MONTHLY_DURING_EARLY_ACCESS = "opt-in-premium-monthly-during-early-access",
  EARLY_ACCESS_PROMOTION = "early-access-promotion",
  FIRST_FREE_WEEK_PROMOTION = "first-free-week-promotion",
}

export type CheckoutSessionMetadata = {
  purpose?: CheckoutSessionPurpose;
};

export type GetSubscriptionPayload = {
  plan: PricingPlan;
  currentPeriodEnd: string | undefined; // DateTime when next payment is created
  paymentFailedAt: string[]; // Array of Date to string

  currentPaymentMethod:
    | { brand: string; last4: string; expMonth: string; expYear: string }
    | undefined;

  cancelAtPeriodEnd: boolean;
  switchBillingCycle: boolean; // Flag if scheduled to switch billing cycle within same product (between PREMIUM_1MO and PREMIUM_6MO)

  earlyAccessInfo:
    | {
        status: EarlyAccessStatus;
        activeAt?: string;
        createdAt: string;
      }
    | undefined;

  promotion: {
    earlyAccessPromotion?: boolean;
  };
};

export type GetTaxPayload = Partial<
  Record<PricingPlan, { normal: string; promo: Record<string, string> }>
>;
