import React from "react";
import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import CloseIcon from "@/public/close.svg";
import CoverBackgroundSvg from "@/public/cover_background.svg";

import { TRIAL_DURATION_IN_SECONDS } from "../../../cross-platform-resources/back-end/EarlyAccess";
import QueryStringCenter from "../../../cross-platform-resources/front-end/QueryStringCenter";
import { simpleDateString } from "../../../utils/DateTime";
import { sentryReport } from "../../../utils/Error";
import useAuth from "../../../utils/Hooks/useAuth";
import GetSubscription from "../../../utils/Queries/pricing/get-subscription";
import CustomModal from "../../CustomModal";

import styles from "./AfterAddPaymentModal.module.scss";

const AfterAddPaymentModal = () => {
  const router = useRouter();
  const { authStatus } = useAuth({});

  const [afterAddPaymentModal, setAfterAddPaymentModal] = useState({
    open: false,
  });

  const {
    isLoading,
    isRefetching,
    data: subscription_data,
    error: subscription_error,
  } = GetSubscription.useQueryWrapper({
    enabled: authStatus === "authenticated",
  });

  // Check error of query
  useEffect(() => {
    if (isLoading === false && isRefetching === false && subscription_error) {
      sentryReport(subscription_error);
    }
  }, [isLoading, isRefetching, subscription_error]);

  // Effect for auto show AfterAddPaymentModal
  useEffect(() => {
    const query = router.query;

    const successAddPaymentMethod =
      query[
        QueryStringCenter.GLOBAL
          .EARLY_ACCESS_PROMOTION_SUCCESS_ADD_PAYMENT_METHOD
      ];

    const alreadyHasPaymentMethod =
      query[
        QueryStringCenter.GLOBAL
          .EARLY_ACCESS_PROMOTION_USER_ALREADY_HAS_PAYMENT_METHOD
      ];

    if (
      typeof query === "object" &&
      subscription_data?.promotion.earlyAccessPromotion &&
      (successAddPaymentMethod !== undefined ||
        alreadyHasPaymentMethod !== undefined)
    ) {
      setAfterAddPaymentModal({
        open: true,
      });
    }
  }, [subscription_data, JSON.stringify(router.query)]);

  const firstDatePayment = () => {
    if (!subscription_data?.earlyAccessInfo?.activeAt) {
      return simpleDateString(new Date());
    }

    const earlyAccessStart = new Date(
      subscription_data.earlyAccessInfo.activeAt
    );

    const earlyAccessEnd = new Date(
      (earlyAccessStart.getTime() / 1000 + TRIAL_DURATION_IN_SECONDS) * 1000
    );

    return simpleDateString(earlyAccessEnd);
  };

  const removeQueryStringPromotionAfterAddPayment = () => {
    const url = new URL(process.env.NEXT_PUBLIC_FRONTEND_HOST + router.asPath);
    const params = new URLSearchParams(url.search);

    params.delete(
      QueryStringCenter.GLOBAL.EARLY_ACCESS_PROMOTION_SUCCESS_ADD_PAYMENT_METHOD
    );
    params.delete(
      QueryStringCenter.GLOBAL
        .EARLY_ACCESS_PROMOTION_USER_ALREADY_HAS_PAYMENT_METHOD
    );

    const newParams = params.toString();

    let newRoute = router.route;
    if (newParams.length !== 0) {
      newRoute += `?${newParams}`;
    }

    router.replace(newRoute);
  };

  const paymentAmount = () => {
    const payment = 1.99 * 6 * (75 / 100);
    return `$${payment.toFixed(2)} (before tax)`;
  };

  return (
    <React.Fragment>
      <CustomModal
        isOpen={afterAddPaymentModal.open}
        onClose={() => {
          setAfterAddPaymentModal({ open: false });
          removeQueryStringPromotionAfterAddPayment();
        }}
      >
        <div className={styles.root}>
          <CoverBackgroundSvg
            className={styles.cover}
            preserveAspectRatio="none"
          />

          <div className={styles.header}>
            <p>
              <span>🎉</span> You’re all set for 25% off 6 months
            </p>
            <div className={styles.iconContainer}>
              <CloseIcon
                onClick={() => {
                  setAfterAddPaymentModal({ open: false });
                  removeQueryStringPromotionAfterAddPayment();
                }}
              />
            </div>
          </div>

          <div className={styles.text}>
            <p>We&apos;re glad that you love our service.</p>

            <p className={styles.bold}>
              First payment of {paymentAmount()} will be made on{" "}
              {firstDatePayment()} on your behalf.
            </p>

            <p>
              You can always make changes to this schedule anytime in Account
              page.
            </p>
          </div>

          <div className={styles.action}>
            <button
              onClick={() => {
                setAfterAddPaymentModal({ open: false });
                removeQueryStringPromotionAfterAddPayment();
              }}
            >
              Got it!
            </button>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default AfterAddPaymentModal;
