import React from "react";

import { AlertProps } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      sx={{
        "&.MuiAlert-root": {
          backgroundColor: "var(--color-error)",
        },
      }}
    />
  );
});

export default Alert;
