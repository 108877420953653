import React, { useState } from "react";

import { ClickAwayListener, Fade, Popper } from "@mui/material";
import { Placement } from "@popperjs/core";
import clsx from "clsx";

import styles from "./CustomPopper.module.scss";

type ElementFn = (popperAttr: {
  popperStyles: Record<string, string>;
  open: boolean;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => JSX.Element;

type Props = {
  icon: ElementFn;
  popperContent: ElementFn;

  arrow?: boolean;
  placement?: Placement;
  offset?: [number, number]; // [x, y]
  classes?: {
    wrapper?: string;
    iconWrapper?: string;
    popperWrapper?: string;
    popper?: string;
  };

  // Override with parent control: These two go together
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  // Override with parent control: These two go together
  anchorEl?: HTMLElement | null;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;

  disableIconWrapperClick?: boolean;
  disableClickaway?: boolean;
};

const CustomPopper = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.setAnchorEl) {
      props.setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }

    if (props.setOpen) {
      props.setOpen((previousOpen) => !previousOpen);
    } else {
      setOpen((previousOpen) => !previousOpen);
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (props.disableClickaway) {
          return;
        }

        if (!props.setOpen) {
          setOpen(false);
        } else {
          props.setOpen(false);
        }
      }}
    >
      <div className={props.classes?.wrapper}>
        <div
          className={props.classes?.iconWrapper}
          onClick={props.disableIconWrapperClick ? undefined : handleClick}
        >
          {props.icon({
            popperStyles: styles,
            open: props.open ?? open,
            setAnchorEl: props.setAnchorEl ?? setAnchorEl,
            setOpen: props.setOpen ?? setOpen,
          })}
        </div>

        <Popper
          className={clsx(styles.popperWrapper, props.classes?.popperWrapper)}
          open={props.open ?? open}
          anchorEl={props.anchorEl !== undefined ? props.anchorEl : anchorEl}
          transition
          placement={props.placement ?? "bottom"}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: props.offset ?? [0, 12],
              },
            },
            {
              name: "arrow",
              enabled: props.arrow ?? false,
              options: {
                element: arrowRef,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <React.Fragment>
              <Fade {...TransitionProps} timeout={300}>
                <div>
                  <div className={clsx(styles.popper, props.classes?.popper)}>
                    {props.popperContent({
                      popperStyles: styles,
                      open: props.open ?? open,
                      setOpen: props.setOpen ?? setOpen,
                      setAnchorEl: props.setAnchorEl ?? setAnchorEl,
                    })}
                  </div>

                  {/* https://github.com/mui/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js */}
                  {props.arrow ? (
                    <div ref={setArrowRef} className={styles.arrow} />
                  ) : null}
                </div>
              </Fade>
            </React.Fragment>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default CustomPopper;
