import { useEffect, useState } from "react";
import React from "react";

import { CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { useRouter } from "next/router";

import { TRIAL_DURATION_IN_SECONDS } from "../../../cross-platform-resources/back-end/EarlyAccess";
import {
  FIRST_FREE_WEEK_PROMOTION_DURATION_IN_SECONDS,
  GetSubscriptionPayload,
  PricingPlan,
} from "../../../cross-platform-resources/back-end/Pricing";
import {
  forceSignOutIfInvalidAccessToken,
  isFormattedError,
  sentryReport,
} from "../../../utils/Error";
import useAuth from "../../../utils/Hooks/useAuth";
import GetSubscription from "../../../utils/Queries/pricing/get-subscription";
import Alert from "../../Alert";
import CustomModal from "../../CustomModal";

import styles from "./LimitedOfferBanner.module.scss";

export const limitedOfferTimeRemainingInSeconds = (
  subscriptionData: GetSubscriptionPayload | undefined
) => {
  if (
    subscriptionData?.plan === PricingPlan.FREE &&
    subscriptionData.earlyAccessInfo?.activeAt
  ) {
    const earlyAccessActive = new Date(
      subscriptionData.earlyAccessInfo.activeAt
    );

    const earlyAccessEnd = new Date(
      (earlyAccessActive.getTime() / 1000 + TRIAL_DURATION_IN_SECONDS) * 1000
    );

    return (
      FIRST_FREE_WEEK_PROMOTION_DURATION_IN_SECONDS -
      (new Date().getTime() - earlyAccessEnd.getTime()) / 1000
    );
  }

  return 0;
};

export const showLimitedOfferBanner = (
  subscriptionData: GetSubscriptionPayload | undefined
) => {
  if (
    subscriptionData?.plan === PricingPlan.FREE &&
    subscriptionData.earlyAccessInfo?.activeAt &&
    limitedOfferTimeRemainingInSeconds(subscriptionData) > 0
  ) {
    return true;
  }

  return false;
};

export const useShowLimitedOfferBanner = () => {
  const { authStatus, isAuthenticated } = useAuth({});

  const subscription = GetSubscription.useQueryWrapper({
    enabled: authStatus === "authenticated",
  });

  const [limitedOfferBanner, setLimitedOfferBanner] = useState({
    show: false,
  });

  // Effect for check show limited offer banner
  useEffect(() => {
    let timeout = -1;

    setLimitedOfferBanner({
      show: isAuthenticated && showLimitedOfferBanner(subscription.data),
    });

    const remainingTime = limitedOfferTimeRemainingInSeconds(subscription.data);

    if (remainingTime > 0) {
      timeout = window.setTimeout(() => {
        setLimitedOfferBanner({
          show: showLimitedOfferBanner(subscription.data),
        });
      }, (remainingTime + 5) * 1000); // 5 seconds margin
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isAuthenticated, JSON.stringify(subscription.data)]);

  return limitedOfferBanner;
};

const LimitedOfferBanner = () => {
  const router = useRouter();
  const { session, authStatus } = useAuth({});
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [loadingModal, setLoadingModal] = useState({ open: false });
  const [error, setError] = useState("");

  const { data: subscription_data } = GetSubscription.useQueryWrapper({
    enabled: authStatus === "authenticated",
  });

  // Effect for setting small screen
  useEffect(() => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
    window.addEventListener("resize", handleResize);

    // Unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
  };

  const onRedeemOffer = () => {
    if (!session) {
      return;
    }

    setLoadingModal({ open: true });

    axios({
      method: "post",
      url: `${process.env.NEXT_PUBLIC_BACKEND_HOST}/pricing/redeem-first-free-week-promotion`,
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
      data: {
        currentAsPath: router.asPath,
      },
    })
      .then((res) => {
        const checkoutSessionUrl: string = res.data;
        return router.push(checkoutSessionUrl);
      })
      .catch((err) => {
        sentryReport(err);
        forceSignOutIfInvalidAccessToken(err);

        if (isFormattedError(err)) {
          setError(err.response.data.message);
        }

        setLoadingModal({ open: false });
      });
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  let remainingDays: string | number = Math.floor(
    limitedOfferTimeRemainingInSeconds(subscription_data) / (24 * 60 * 60)
  );

  remainingDays = remainingDays === 0 ? "< 1" : remainingDays;

  return (
    <React.Fragment>
      <div
        className={clsx(
          styles.root,
          "max-h-[var(--height-navbar-banner)] grow shrink-0"
        )}
        onClick={() => {
          if (isSmallScreen) {
            onRedeemOffer();
          }
        }}
      >
        <div
          className={styles.text}
          onClick={() => {
            if (!isSmallScreen) {
              onRedeemOffer();
            }
          }}
        >
          {">>>"} 🎉 LIMITED OFFER:
          <span>50% off for next 6 months</span>⏰ {remainingDays} days left
          until offer expires.
          <span>Redeem NOW!</span>
          {"<<<"}
        </div>
      </div>

      <CustomModal
        isOpen={loadingModal.open}
        onClose={() => {
          return;
        }}
      >
        <div className={styles.loadingContainer}>
          <p>Loading...</p>
          <CircularProgress />
        </div>
      </CustomModal>

      <Snackbar
        open={error.length !== 0}
        autoHideDuration={5000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default LimitedOfferBanner;
